import {createSlice} from "@reduxjs/toolkit";
import {loadListFromBackend} from "../utils/api/loadListFromBackend";

export const stewardsSlice = createSlice({
  name: "stewards",
  initialState: {
    list: [],
    init: false,
    editing: {},
  },
  reducers: {
    initContext: (state, action) => {
      state.init = action.payload;
    },
    initList: (state, action) => {
      state.init = action.payload.project;
      state.list = action.payload.list;
    },
  },
});

const asyncActions = {
  loadList: () => async (dispatch, getState) => {
    const state = getState();
    const projectId = state.currentProject.project._id;
    const connected = state.currentUser.connected;

    await loadListFromBackend(
      `stewards${connected ? "" : "/public"}`,
      projectId,
      state.stewards.init,
      () => dispatch(stewardsActions.initContext(projectId)),
      (data) => dispatch(stewardsActions.initList({list: data, project: projectId}))
    );
  },
};

export const stewardsSelectors = {
  selectList: (state) => state.stewards.list,
};

export const stewardsReducer = stewardsSlice.reducer;

export const stewardsActions = {
  ...stewardsSlice.actions,
  ...asyncActions,
};
