import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import isHotkey from "is-hotkey";
import {useTranslation} from "react-i18next";
import {withSentryProfiler} from "./app/services/sentry";
import {useUserLocale} from "./app/services/i18n";
import {lazyWithRetry} from "./utils/lazyWithRetry";
import PwaInstallPromptHandler from "./common/components/PwaInstallPromptHandler";
import {useStagingInfoNotification} from "./common/hooks/useStagingInfoNotification";
import {Redirect} from "./common/pages/Redirect";
import {Route, Routes, Link} from "react-router-dom";
import {currentUserActions, currentUserSelectors} from "./features/currentUser";
import {usersSelectors} from "./features/users";
import {Pending} from "./common/components/Pending";
import {useDarkModeTheme} from "./common/layout/DynamicProjectThemeProvider";
import {useRegisterServiceWorker} from "./common/hooks/useRegisterServiceWorker";

// @ts-ignore
import {ReactComponent as LogoNoe} from "./app/images/logo-base.svg";

// Do not prefetch Forgot password pages
const ForgotPassword = lazyWithRetry(() => import("./common/pages/auth/ForgotPassword.js"));
const ResetPassword = lazyWithRetry(() => import("./common/pages/auth/ResetPassword.js"));

const LogIn = lazyWithRetry(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackFetchPriority: "low" */
      "./common/pages/auth/LogIn.js"
    )
);
const SignUp = lazyWithRetry(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackFetchPriority: "low" */
      "./common/pages/auth/SignUp.js"
    )
);

const MainLayout = lazyWithRetry(
  () => import(/* webpackPrefetch: true */ "./routes/MainLayout.js")
);
const ProjectLayout = lazyWithRetry(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackFetchPriority: "high" */
      "./routes/ProjectLayout"
    )
);
const PublicProjectLayout = lazyWithRetry(
  () => import(/* webpackPrefetch: true */ "./routes/PublicProjectLayout")
);
const ProjectList = lazyWithRetry(
  () => import(/* webpackPrefetch: true */ "./routes/projects/ProjectList")
);

const isCtrlSavePressed = isHotkey("mod+S");

function App() {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const connected = useSelector(currentUserSelectors.selectConnected);
  const user = useSelector(usersSelectors.selectEditing);

  useUserLocale();
  useDarkModeTheme();

  // Remove Ctrl+S default behavior
  useEffect(() => {
    const onCtrlSKeyDown = (event: KeyboardEvent) =>
      isCtrlSavePressed(event) && event.preventDefault();
    window.addEventListener("keydown", onCtrlSKeyDown);
    return () => {
      window.removeEventListener("keydown", onCtrlSKeyDown);
    };
  }, []);

  useEffect(() => {
    if (connected === undefined) {
      dispatch(currentUserActions.refreshAuthTokens());
    }
  }, [connected, dispatch]);

  useStagingInfoNotification();

  useRegisterServiceWorker();

  const connectionPageFooter = (
    <Link
      to="/public-projects"
      style={{
        position: "relative",
        color: "white",
        alignSelf: "center",
        marginTop: 26,
        paddingBottom: -32,
        opacity: 0.9,
      }}>
      {t("common:connectionPage.seeOtherEventsAvailable")}
    </Link>
  );

  return (
    <>
      {/* Show the PWA prompt if the user has some registration loaded, has registered for real
      to an event, and wait until the user has been show the session page user tour before prompting */}
      <PwaInstallPromptHandler
        shouldShow={
          (registration) =>
            registration?._id &&
            registration?.booked &&
            user.shownTours?.includes("subscribingToSessions") // Don't show until the subscribingToSessions tour has not been shown to the user.
        }
        delay={15000}
      />

      <Routes>
        {/*Public projects are always accessible*/}
        <Route path="/public-projects" element={<MainLayout page="public-projects" />}>
          <Route path="" element={<ProjectList displayAllPublicProjects />} />
        </Route>

        {connected === false && (
          <>
            {/*Simple root auth URLs first*/}
            <Route
              path="/login"
              element={
                <LogIn
                  subtitle={t("common:connectionPage.participantLogIn")}
                  footer={connectionPageFooter}
                />
              }
            />
            <Route path="/signup" element={<SignUp footer={connectionPageFooter} />} />
            <Route
              path="/forgotpassword"
              element={<ForgotPassword footer={connectionPageFooter} />}
            />
            <Route
              path="/resetpassword"
              element={<ResetPassword footer={connectionPageFooter} />}
            />

            {/*Auth URLs with project ID just after*/}
            <Route
              path="/:projectId/login"
              element={
                <LogIn
                  subtitle={t("common:connectionPage.participantLogIn")}
                  footer={connectionPageFooter}
                />
              }
            />
            <Route path="/:projectId/signup" element={<SignUp footer={connectionPageFooter} />} />
            <Route
              path="/:projectId/forgotpassword"
              element={<ForgotPassword footer={connectionPageFooter} />}
            />

            {/*Then, redirect /projects to /public-projects if not connected, so /projects is not considered as a project slug*/}
            <Route path="/projects" element={<Redirect to="/public-projects" />} />

            {/*Project welcome page when not connected*/}
            <Route path="/:projectId/*" element={<PublicProjectLayout />} />

            {/*Redirect all the rest to /public-projects*/}
            <Route path="/*" element={<Redirect from="/*" to="/public-projects" />} />
          </>
        )}
        {connected && (
          <>
            {/*Main Layout*/}
            <Route path="/projects" element={<MainLayout page="projects" />}>
              <Route path="" element={<ProjectList />} />
            </Route>

            {/*Project Layout*/}
            <Route path="/:projectId/*" element={<ProjectLayout />} />

            {/*Redirect all the rest to /projects (which is accessible only when connected)*/}
            <Route path="/*" element={<Redirect to="/projects" />} />
          </>
        )}

        {/* PENDING SCREEN ON FIRST PAGE LOAD */}
        <Route
          path="/*"
          element={
            <Pending
              noFadeIn
              animationType={"breathe"}
              logo={
                <div style={{width: 130, height: 130}}>
                  <LogoNoe />
                </div>
              }
            />
          }
        />
      </Routes>
    </>
  );
}

export default withSentryProfiler(App);
