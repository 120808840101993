import type {CardProps} from "antd";
import {Card} from "antd";
import React from "react";
import {ReactNode} from "react";

export type CardElementProps = {
  title?: string | ReactNode;
  subtitle?: string;
  icon?: ReactNode;
  customButtons?: ReactNode;
  borderless?: boolean;
  greyedOut?: boolean;
} & CardProps;

export const CardElement = ({
  title,
  subtitle,
  icon,
  customButtons,
  children,
  borderless,
  greyedOut,
  style,
  bodyStyle,
  ...otherProps
}: CardElementProps) => (
  <Card
    title={
      title ? (
        <div
          className="header-space-between"
          style={{whiteSpace: "normal", gap: 12, marginTop: 12, marginBottom: 12}}>
          {/* Icon / Text */}
          <div className={"containerH"} style={{gap: 10, flexGrow: 1000}}>
            {icon && <span style={{opacity: 0.8}}>{icon}</span>} {title}
          </div>
          {/* Buttons */}
          {customButtons && <div className="containerH buttons-container">{customButtons}</div>}
        </div>
      ) : undefined
    }
    style={{
      flexGrow: 1,
      marginBottom: 26,
      backgroundColor: greyedOut ? "var(--colorBgElevated)" : undefined,
      ...style,
    }}
    bodyStyle={{
      padding: borderless ? 0 : undefined,
      ...bodyStyle,
    }}
    {...otherProps}>
    {subtitle && <div style={borderless ? {padding: "10px 24px"} : undefined}>{subtitle}</div>}
    {children}
  </Card>
);
