import {createSlice} from "@reduxjs/toolkit";
import {loadListFromBackend} from "../utils/api/loadListFromBackend";

export const activitiesSlice = createSlice({
  name: "activities",
  initialState: {
    list: [],
    init: false,
    editing: {},
  },
  reducers: {
    initContext: (state, action) => {
      state.init = action.payload;
    },
    initList: (state, action) => {
      state.init = action.payload.project;
      state.list = action.payload.list;
    },
  },
});

const asyncActions = {
  loadList:
    ({forceLoad} = {}) =>
    async (dispatch, getState) => {
      const state = getState();
      const projectId = state.currentProject.project._id;
      const connected = state.currentUser.connected;

      await loadListFromBackend(
        `activities${connected ? "" : "/public"}`,
        projectId,
        state.activities.init,
        () => dispatch(activitiesActions.initContext(projectId)),
        (data) => dispatch(activitiesActions.initList({list: data, project: projectId})),
        forceLoad
      );
    },
};

export const activitiesSelectors = {
  selectList: (state) => state.activities.list,
};

export const activitiesReducer = activitiesSlice.reducer;

export const activitiesActions = {
  ...activitiesSlice.actions,
  ...asyncActions,
};
