import {FormItemProps} from "./FormItem";
import {SelectProps} from "antd";
import {useTranslation} from "react-i18next";
import {SelectInput} from "./SelectInput";
import React from "react";

const noeObjectsEndpoints = [
  "categories",
  "places",
  "stewards",
  "activities",
  "teams",
  "sessions",
] as const;
export type NOEEndpoint = (typeof noeObjectsEndpoints)[number];

const noeObjectsEndpointsExtended = [...noeObjectsEndpoints, "registrations"] as const;
export type NOEEndpointExtended = (typeof noeObjectsEndpointsExtended)[number];

export const useNOEObjectsWithLabels = (extended = false) => {
  const {t} = useTranslation();
  return (extended ? noeObjectsEndpointsExtended : noeObjectsEndpoints).map((endpoint) => ({
    label: t(`${endpoint}:label_other`),
    value: endpoint,
  }));
};

export const NOEObjectSelectInput = ({
  extended,
  ...props
}: Omit<FormItemProps<SelectProps>, "options"> & {
  extended?: boolean;
}) => {
  const options = useNOEObjectsWithLabels(extended);
  return <SelectInput options={options} allowClear {...props} />;
};
