import React, {useEffect, useState} from "react";
import {SelectProps} from "antd";
import dayjs from "dayjs";
import capitalize from "lodash/capitalize";
import {SelectComponent} from "./SelectInput";
import {Stack} from "@mui/material";
import {useTranslation} from "react-i18next";

const DB_DATE_STORE_FORMAT = "DD/MM/YYYY";

export const DayInputComponent = ({onChange, value, ...props}: SelectProps) => {
  const {t} = useTranslation();

  const parsedDate = value ? dayjs(value, DB_DATE_STORE_FORMAT) : null;
  const [day, setDay] = useState(parsedDate ? parsedDate.date() : null);
  const [month, setMonth] = useState(parsedDate ? parsedDate.month() + 1 : null);
  const [year, setYear] = useState(parsedDate ? parsedDate.year() : null);

  const date = day && month && year ? dayjs(`${year}-${month}-${day}`) : null;
  const currentYear = dayjs().year();

  // Handle change
  useEffect(() => {
    if (date?.isValid()) {
      onChange(date.format(DB_DATE_STORE_FORMAT));
    } else {
      onChange(null);
    }
  }, [date]);

  return (
    <Stack gap={1} direction={"row"}>
      <SelectComponent
        {...props}
        id={props.id + "Day"}
        showSearch
        placeholder={t("common:dayInput.day")}
        style={{maxWidth: 110}}
        value={date?.date() || day}
        onChange={setDay}
        options={Array.from(
          {length: (date || (month && dayjs(`2000-${month}-01`)))?.daysInMonth() || 31},
          (_, i) => i + 1
        ).map((day) => ({
          value: day,
          label: day,
          key: day,
        }))}
      />
      <SelectComponent
        {...props}
        id={props.id + "Month"}
        showSearch
        placeholder={t("common:dayInput.month")}
        style={{maxWidth: 130}}
        value={month}
        onChange={setMonth}
        options={Array.from({length: 12}, (_, i) => i).map((month) => ({
          value: month + 1,
          label: capitalize(dayjs().month(month).format("MMMM")),
          key: month + 1,
        }))}
      />
      <SelectComponent
        {...props}
        id={props.id + "Year"}
        showSearch
        placeholder={t("common:dayInput.year")}
        style={{maxWidth: 110}}
        value={year}
        onChange={setYear}
        options={Array.from({length: 100}, (_, i) => currentYear - i).map((year) => ({
          value: year,
          label: year,
          key: year,
        }))}
      />
    </Stack>
  );
};
