import React from "react";
import pick from "lodash/pick";
import {Form, Input} from "antd";

const FormRendererField = ({field, form, disabled, minimalist}) => {
  // Setup the formItemProps for Antd FormItem
  const formItemProps = {
    name: field.key, // Key and name are the same here. Key is originally the name in the formbuilder, but name is the key used by AntDesign for its fields
    ...pick(field, [
      "key",
      "tooltip",
      "label",
      "help",
      "extra",
      "htmlFor",
      "style",
      "noStyle",
      "validateStatus",
      "hasFeedback",
      "className",
      "shouldUpdate",
      "dependencies",
    ]),
    extra: field.description,
    ...field.formItemProps,
  };

  // Handle field props
  const rules = [...(field.rules || [])];
  if (field.required) {
    rules.unshift({required: true, message: field.message || undefined});
  }
  const fieldProps = {
    ...pick(field, [
      "getValueFromEvent",
      "getValueProps",
      "normalize",
      "trigger",
      "preserve",
      "valuePropName",
      "validateTrigger",
      "validateFirst",
    ]),
    rules,
    ...field.fieldProps,
  };
  Object.assign(formItemProps, fieldProps);

  // Handle widget props
  const widgetProps = {
    ...pick(field, [
      "label",
      "value",
      "options",
      "placeholder",
      "type",
      "className",
      "autoFocus",
      "class",
      "bordered",
      "onChange",
      "components",
      "min",
      "max",
    ]),
    disabled: field.disabled || disabled,
    form,
    ...field.widgetProps,
  };

  let FieldWidget = field.widget || Input;

  if (minimalist) {
    formItemProps.label = formItemProps.extra = undefined;
    (formItemProps.style ||= {}).marginBottom = 0;
  }

  return (
    <Form.Item {...formItemProps}>
      <FieldWidget {...widgetProps}>{field.children || null}</FieldWidget>
    </Form.Item>
  );
};
export default FormRendererField;
