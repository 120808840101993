import {Checkbox, DatePicker, Input, InputNumber} from "antd";
import React, {Suspense} from "react";
import FormRenderer from "./FormRenderer";
import dayjs from "dayjs";
import {CardElement} from "../CardElement";
import {lazyWithRetry} from "../../../utils/lazyWithRetry";
import TextDisplayer from "../TextDisplayer";
import {RadioGroupComponent} from "../../inputs/RadioGroupInput";
import {CheckboxGroupComponent} from "../../inputs/CheckboxGroupInput";
import {SelectComponent} from "../../inputs/SelectInput";
import {DayInputComponent} from "../../inputs/DayInput";
import {FormItemProps} from "antd";
import {phoneValidator} from "../../../utils/phoneValidator";
import FieldType from "./index";
import {t} from "i18next";
import {FieldProps} from "uniforms";
const PhoneInputComponent = lazyWithRetry(() =>
  import(/* webpackPrefetch: true */ "../../inputs/PhoneInput").then((module) => ({
    default: module["PhoneInputComponent"],
  }))
);

type Rule = FormItemProps["rules"][number];

const addRuleToFieldProps =
  (getRule: (field: FieldType) => Rule) =>
  ({rules = [], ...field}) => ({...field, rules: [getRule(field), ...rules]});

const addSelectedCountRule = (field) => {
  field = field.required
    ? addRuleToFieldProps((field) => ({
        type: "array",
        min: field.selectedCount?.min,
      }))(field)
    : field;
  field = addRuleToFieldProps((field) => ({
    type: "array",
    max: field.selectedCount?.max,
  }))(field);
  return field;
};

const addMinMaxRule = (field) => ({
  ...field,
  ...field.minMaxNumber,
});

const addDefaultPlaceholder = (placeholder: string) => (field: FieldProps) => ({
  ...field,
  placeholder: field.placeholder || placeholder,
});

const addCheckboxRequiredRule = ({required, label, rules = [], ...field}) => {
  if (required) {
    // If required, modify the default required rule to only accept "true" and fail if "false" is given.
    rules.unshift({
      required: true,
      transform: (value) => value || undefined, // Transform value before init. If not true, then tell it's undefined.
      type: "boolean",
      message: t("common:formValidation.requiredCustom", {label}),
    });

    // Add manually the required mark
    label = (
      <>
        <span style={{color: "#dc4446", marginRight: 4}}>*</span>
        {label}
      </>
    );
  }
  return {...field, rules, valuePropName: "checked", placeholder: label};
};

const defineWidgets = () => {
  // Text Fields
  FormRenderer.defineWidget(
    "text",
    Input,
    addDefaultPlaceholder(t("common:fieldsBuilder.defaultPlaceholders.text"))
  );
  FormRenderer.defineWidget("url", Input, (field) =>
    addDefaultPlaceholder(t("common:fieldsBuilder.defaultPlaceholders.url"))(
      addRuleToFieldProps(() => ({type: "url"}))(field)
    )
  );
  FormRenderer.defineWidget("email", Input, (field) =>
    addDefaultPlaceholder(t("common:fieldsBuilder.defaultPlaceholders.email"))(
      addRuleToFieldProps(() => ({type: "email"}))(field)
    )
  );
  FormRenderer.defineWidget(
    "longText",
    (props) => <Input.TextArea autoSize={{minRows: 2}} {...props} />,
    addDefaultPlaceholder(t("common:fieldsBuilder.defaultPlaceholders.text"))
  );
  FormRenderer.defineWidget(
    "phoneNumber",
    (props) => (
      <Suspense fallback={null}>
        <PhoneInputComponent bordered {...props} />
      </Suspense>
    ),
    (field) =>
      addDefaultPlaceholder(t("common:fieldsBuilder.defaultPlaceholders.phone"))(
        addRuleToFieldProps(() => ({validator: phoneValidator}))(field)
      )
  );
  FormRenderer.defineWidget("number", InputNumber, addMinMaxRule);

  // Choice selection
  FormRenderer.defineWidget(
    "checkbox",
    ({placeholder, ...props}) => <Checkbox {...props}>{placeholder}</Checkbox>,
    addCheckboxRequiredRule
  );
  FormRenderer.defineWidget("radioGroup", RadioGroupComponent);
  FormRenderer.defineWidget(
    "select",
    (props) => <SelectComponent allowClear {...props} />,
    addDefaultPlaceholder(t("common:fieldsBuilder.defaultPlaceholders.select"))
  );
  FormRenderer.defineWidget("checkboxGroup", CheckboxGroupComponent, addSelectedCountRule);
  FormRenderer.defineWidget(
    "multiSelect",
    (props) => <SelectComponent allowClear {...props} mode="multiple" />,
    (field) =>
      addDefaultPlaceholder(t("common:fieldsBuilder.defaultPlaceholders.multiSelect"))(
        addSelectedCountRule(field)
      )
  );

  // Dates
  FormRenderer.defineWidget("datetime", ({value, onChange, ...props}) => (
    <DatePicker
      {...{
        ...props,
        format: dayjs.localeData().longDateFormat("LLL"),
        value: value ? dayjs(value) : undefined,
        onChange: (value) => onChange(value?.toISOString()),
        showTime: {minuteStep: 5},
      }}
    />
  ));
  FormRenderer.defineWidget("day", (props) => <DayInputComponent {...props} bordered />);

  // HTML Content
  FormRenderer.defineWidget(
    "content",
    (props) => (
      <div style={{marginBottom: 24}}>
        <TextDisplayer {...props} />
      </div>
    ),
    (field) => ({
      noStyle: true,
      value: field.content,
    })
  );

  // Card where you can put stuff inside
  FormRenderer.defineWidget(
    "panel",
    ({label, components, form}) => (
      <CardElement title={label}>
        <FormRenderer form={form} fields={components} />
      </CardElement>
    ),
    (field) => ({...field, noStyle: true})
  );
};

export default defineWidgets;
