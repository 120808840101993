// This function is duplicated in api/src/helpers/registration.helper.ts
import dayjs from "dayjs";
import {getVolunteeringCoefficient} from "./sessionsUtilities";

export const isParticipantAvailable = (sessionSlots, availabilitySlots) => {
  if (!sessionSlots || !availabilitySlots) return false;

  for (const sessionSlot of sessionSlots) {
    const slotStart = new Date(sessionSlot.start);
    const slotEnd = new Date(sessionSlot.end);

    const compatibleUserAvailability = availabilitySlots.find((availabilitySlot) => {
      const availabilitySlotStart = new Date(availabilitySlot.start);
      const availabilitySlotEnd = new Date(availabilitySlot.end);

      // Return true if the slot is withing the user availability
      return availabilitySlotStart <= slotStart && availabilitySlotEnd >= slotEnd;
    });

    // If one of the slots is not compatible, then return false
    if (!compatibleUserAvailability) return false;
  }

  return true;
};
export const getSessionMetadata = (session, currentRegistration?) => {
  // Session full or not
  const sessionIsFull =
    session.computedMaxNumberOfParticipants &&
    session.numberParticipants >= session.computedMaxNumberOfParticipants;

  // If session is volunteering and begins soon
  const volunteeringBeginsSoon =
    getVolunteeringCoefficient(session) > 0 && dayjs(session.start).diff(dayjs(), "day") < 2;

  // Participant status: available, steward, and conflicts with other subscriptions, and subscribed or not
  const participantIsNotAvailable = !isParticipantAvailable(
    session.slots,
    currentRegistration?.availabilitySlots
  );

  const alreadyStewardOnOtherSession =
    session.sameTimeSessions?.find((sts) => sts.sameTimeSessionSteward) !== undefined;
  const alreadySubscribedToOtherSession =
    session.sameTimeSessions?.find((sts) => sts.sameTimeSessionRegistered) !== undefined;
  const registrationIncomplete = !currentRegistration?.inDatabase.everythingIsOk;
  const isSteward = session.isSteward;
  const shouldBeAddedAutomagicallyInUsersPlanning =
    session.everybodyIsSubscribed && !participantIsNotAvailable && !registrationIncomplete;

  // Only make transparent if the participant is not subscribed to the session + if it is not yet registered to the project
  const shouldBeDimmed =
    !isSteward &&
    !registrationIncomplete &&
    !shouldBeAddedAutomagicallyInUsersPlanning && // Dim only if the session is not addable automatically to the user planning
    ((participantIsNotAvailable && !session.subscribed) || //... and if the participant is not available at all on these dates
      (!session.subscribed && // ... or if it is not subscribed and the following conditions are true
        (sessionIsFull || alreadySubscribedToOtherSession || alreadyStewardOnOtherSession)));

  const inConflictWithRegistrationDates =
    participantIsNotAvailable && (isSteward || session.subscribed);

  return {
    isSteward,
    sessionIsFull,
    participantIsNotAvailable,
    alreadyStewardOnOtherSession,
    alreadySubscribedToOtherSession,
    registrationIncomplete,
    volunteeringBeginsSoon,
    shouldBeDimmed,
    inConflictWithRegistrationDates,
    shouldBeAddedAutomagicallyInUsersPlanning,
  };
};
