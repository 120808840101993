import {flattenFieldComponents} from "../../../utils/registrationsUtilities";

export type Conditional = {show?: "show" | "doNotShow", when?: string, eq?: string};

const isDefinedConditional = (conditional: Conditional) =>
  ["show", "doNotShow"].includes(conditional?.show) && conditional?.when && conditional?.eq;

export const shouldShowField = (conditional?: Conditional, fields, dependencyFieldValue) => {
  // If the conditional parameters are incomplete, just show the field
  if (!isDefinedConditional(conditional)) return true;

  // If the dependency link is broken and dependencyField is undefined, just show the field
  const flatFieldComponents = flattenFieldComponents(fields);
  const dependencyField = flatFieldComponents.find((field) => field.key === conditional.when);
  if (!dependencyField) return true;

  const equalityFunction =
    // For checkboxes
    dependencyField.type === "checkbox"
      ? (value) => (conditional.eq?.toLowerCase() !== "false" ? value : !value)
      : // For numbers
      dependencyField.type === "number"
      ? (value) => value === parseFloat(conditional.eq)
      : // For select boxes. You can make AND assertions using " AND ", and OR assertions with ";".
      // Ex: "one;two AND three;four;five" means we need at least "one" or "two" AND "three", "four" or "five" to be valid
      dependencyField.type === "checkboxGroup" || dependencyField.type === "multiSelect"
      ? (value) =>
          conditional.eq
            .split(" AND ")
            .every((andCondition) =>
              andCondition.split(";").find((eqString) => value?.includes(eqString))
            )
      : // For everything else, that is stored as string: text, select, radio, etc.
        // If there are multiple possible values separated by ";", compare to each of them:
        // Ex: "one" / "one;two" / "heyHowAreYou;someThing;someStuff"
        (value) => conditional.eq.split(";").find((eqString) => eqString === value);

  const shouldShow = dependencyField ? conditional.show === "show" : true;

  return equalityFunction(dependencyFieldValue) ? shouldShow : !shouldShow;
};
