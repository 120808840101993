import {Form, FormProps} from "antd";
import {ReactNode} from "react";
import isHotkey from "is-hotkey";
import {useTranslation} from "react-i18next";
import {FormInstance} from "antd";

/**
 * Safely validates form fields.
 *
 * @param {Object} form - AntD form instance
 * @param {Function} onSuccess - Callback on success
 * @param {Function} onError - Callback on error
 */
export const safeValidateFields = async (
  form: FormInstance,
  onSuccess: () => void,
  onError?: (error: unknown) => void
) => {
  try {
    await form.validateFields();
    if (onSuccess) onSuccess();
  } catch (error) {
    if (onError) onError(error);
  }
};

/**
 * Ant Design Form component with enhanced validation and keyboard shortcuts.
 *
 * @param {function} onValidate - Callback when validation is triggered. It is iven a boolean to tell
 *  if we stay on the element's page or not after the validation
 * @param {ReactNode} children - Child components
 * @param {object} otherProps - Other props to pass to AntD Form
 */
export type FormElementProps = FormProps & {
  onValidate?: (navigateToElementAfterValidation: boolean) => void;
  children?: ReactNode;
};
export const FormElement = ({onValidate, children, ...otherProps}: FormElementProps) => {
  const {t} = useTranslation();

  // Functions to check if Ctrl+S or Ctrl+Enter was pressed
  const isSaveShortcut = isHotkey("mod+S");
  const isSubmitShortcut = isHotkey("mod+Enter");

  return (
    <Form
      layout={"vertical"}
      onKeyDown={(event) => {
        if (isSaveShortcut(event) || isSubmitShortcut(event)) {
          if (onValidate) {
            // If using the legacy manner for forms (ie with onValidate), run the onValidate function
            // If Ctrl+S, stay on the saved/created element's page. If Ctrl+Enter, leave and go back to list.
            const navigateToElementAfterValidation = isSaveShortcut(event);
            onValidate(navigateToElementAfterValidation);
          } else if (otherProps.form && otherProps.onFinish) {
            // if a form element is given and an, trigger the submission of the form
            otherProps.form.submit();
          }
          event.stopPropagation(); // Don't propagate after the form element
          event.preventDefault(); // Prevent the default browser Mod+S shortcut behavior
        }
      }}
      validateMessages={{
        required: t("common:formValidation.required"),
        types: {
          email: t("common:formValidation.types.email"),
          url: t("common:formValidation.types.url"),
        },
        string: {
          min: t("common:formValidation.string.min"),
        },
        array: {
          min: t("common:formValidation.array.min"),
          max: t("common:formValidation.array.max"),
        },
      }}
      requiredMark={false}
      {...otherProps}>
      {children}
    </Form>
  );
};
