import {
  CustomFieldDisplayMode,
  useGetCustomFieldsForEndpointAndMode,
} from "../../../common/hooks/useGetCustomFieldsForEndpointAndMode";
import FormRenderer from "../../../common/components/FormRenderer";
import React from "react";
import {Form} from "antd";
import {FormElement} from "../../../common/inputs/FormElement";
import {useSessionsFilter} from "./useSessionsFilter";
import {FieldCompInput} from "../../../common/components/FieldsBuilder/FieldComp/FieldComp";
import {EyeOutlined} from "@ant-design/icons";
import type {CustomFieldFilter} from "./filterSessionsByCustomFields";

type CustomFieldsFiltersProps = {
  mode: Extract<CustomFieldDisplayMode, "inOrgaFilters" | "inParticipantFilters">;
  showIcon?: boolean;
};

export const fromFormValuesToFilters = (
  allValues: Record<string, Array<string>>,
  customFieldComponents: Array<FieldCompInput>
) =>
  Object.entries(allValues)
    .map(([key, filterValues]) => {
      const customFieldComp = customFieldComponents.find(
        (comp) => comp.key === key
      ) as FieldCompInput;

      // FIXME Filter fields that are not customFields. This can happen if some query params are added in the URL, because they fallback in the queryparams
      if (!customFieldComp) return null;

      return {
        key,
        filterValues,
        type: customFieldComp.type,
        endpoint: customFieldComp.meta.endpoint,
      };
    })
    .filter((el) => !!el) as Array<CustomFieldFilter>;

export const CustomFieldsFilters = ({mode, showIcon}: CustomFieldsFiltersProps) => {
  const customFieldComponentsForFilters = useGetCustomFieldsForEndpointAndMode({mode});
  const [customFieldsFiltersForm] = Form.useForm();
  const [{customFieldsFilters}, setSessionsFilter] = useSessionsFilter(); // If there are search params in the URL, apply them to the filters

  return (
    <>
      {customFieldComponentsForFilters.map((customFieldComp) => (
        <FormElement
          form={customFieldsFiltersForm}
          initialValues={customFieldsFilters}
          onValuesChange={(_, allValues) => {
            setSessionsFilter({customFieldsFilters: allValues});
          }}>
          <FormRenderer
            fields={[
              {
                ...customFieldComp,
                type: "multiSelect",
                placeholder: (
                  <>
                    {showIcon && <EyeOutlined />} {customFieldComp.displayName}…
                  </>
                ),
                style: {minWidth: 200, flexBasis: "15%"},

                // Force the disabled and required values to false to prevent weird behavior https://noe-app.notion.site/champs-personnalis-s-d-sactiv-dans-vue-session-agenda-vider-les-filtres-bddd55e64d824933ae330c97178c82e6?pvs=4
                disabled: false,
                required: false,
              },
            ]}
            form={customFieldsFiltersForm}
            minimalist
          />
        </FormElement>
      ))}
    </>
  );
};
